import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'

const setLightTheme = () => {
  if (typeof document !== `undefined`) {
    document.documentElement.style.setProperty('--primary', '#242424')
    document.documentElement.style.setProperty('--secondary', '#ffffff')
  }
}

const setDarkTheme = () => {
  if (typeof document !== `undefined`) {
    document.documentElement.style.setProperty('--primary', '#ffffff')
    document.documentElement.style.setProperty('--secondary', '#242424')
  }
}

const BackgroundColor = ({ bottomOffset = 50 }) => {
  const ref = useRef()

  useEffect(() => {
    const thresholdArray = steps =>
      Array(steps + 1)
        .fill(0)
        .map((_, index) => index / steps || 0)

    let previousY = 0
    let previousRatio = 0

    const handleIntersect = entries => {
      entries.forEach(entry => {
        const currentY = entry.boundingClientRect.top
        const currentRatio = entry.intersectionRatio
        const { isIntersecting } = entry

        // Scrolling down/up
        if (currentY < previousY) {
          if (currentRatio > previousRatio && isIntersecting) {
            // Scrolling down enter
            setLightTheme()
          } else {
            // Scrolling down leave
            setLightTheme()
          }
        } else if (currentY > previousY && isIntersecting) {
          if (currentRatio < previousRatio) {
            // Scrolling up leave
            setDarkTheme()
          } else {
            // Scrolling up enter
            setDarkTheme()
          }
        }

        previousY = currentY
        previousRatio = currentRatio
      })
    }

    const observer = new IntersectionObserver(handleIntersect, {
      threshold: new Array(101).fill(0).map((zero, index) => index * 0.01),
      rootMargin: '50%'
    })

    observer.observe(ref.current)
  }, [])

  return <Trigger ref={ref} bottomOffset={bottomOffset} />
}

export default BackgroundColor

const Trigger = styled.section`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  transform: translateY(${props => props.bottomOffset}%);
  pointer-events: none;
`
