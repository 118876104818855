import React, { useState, useRef } from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import InView from './in-view'

const FadeIn = ({ children }) => {
  const [animationToggle, setAnimationToggle] = useState(undefined)
  const ref = useRef()

  const fadeAnimation = useSpring({
    opacity: animationToggle ? 1 : 0,
    config: {
      duration: 1000
    }
  })

  return (
    <InView element={ref.current} toggle={animationToggle} setToggle={setAnimationToggle}>
      <FadeWrapper className="fade-wrapper" style={fadeAnimation} ref={ref}>
        {children}
      </FadeWrapper>
    </InView>
  )
}

export default FadeIn

const FadeWrapper = styled(animated.div)`
  position: relative;
`
