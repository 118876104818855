import React, { useContext, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import Parallax from 'kota-react-rellax'
import { ScrollbarContext } from './context/scrollbar-context'

const ParallaxComp = ({ children, speed, element, position }) => {
  const containerRef = useRef()
  const [scrollbar] = useContext(ScrollbarContext)
  const [transform, setTransform] = useState(0)
  const [isTouch, setIsTouch] = useState(undefined)
  const [mobileSpeed, setMobileSpeed] = useState(0)

  useEffect(() => {
    if (scrollbar) {
      const calculateValues = () => {
        const elementPos = containerRef.current.getBoundingClientRect()
        const parentPos = scrollbar.containerEl.getBoundingClientRect()
        const realPosition = elementPos.top - parentPos.height / 2 + elementPos.height / 2
        setTransform(realPosition / speed)
      }

      scrollbar.addListener(() => {
        requestAnimationFrame(calculateValues)
      })
    }
  }, [element, scrollbar, speed])

  // Check if we're using a touch device and restrict to
  // likely a phone or tablet sizes. Only enable Smooth Scroll
  // on non-touch devices
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const checkTouchDevice = () => 'ontouchstart' in window

      if (checkTouchDevice() && window.innerWidth < 1050) {
        setIsTouch(true)
      }
    }
  }, [])

  useEffect(() => {
    setMobileSpeed(speed / 10)
  }, [speed])

  return (
    <>
      {!isTouch && (
        <ParallaxContainer
          ref={containerRef}
          style={{ transform: `translate3d(0, ${transform}px, 0)` }}
          position={position}
        >
          {children}
        </ParallaxContainer>
      )}

      {isTouch && <div style={{ position: 'relative' }}>{children}</div>}
    </>
  )
}

export default ParallaxComp

const ParallaxContainer = styled.div`
  position: ${props => (props.position === 'fill' ? 'absolute' : 'relative')};
  top: ${props => (props.position === 'fill' ? '0' : 'initial')};
  left: ${props => (props.position === 'fill' ? '0' : 'initial')};
  width: ${props => (props.position === 'fill' ? '100%' : 'initial')};
  height: ${props => (props.position === 'fill' ? '100%' : 'initial')};
  will-change: translate;
`
