import React, { useContext } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import { TransitionContext } from './context/transition-context'

import LogoSvg from '../images/logo.svg'
import KotaLogoSvg from '../images/kota-logo.svg'
import FacebookSvg from '../images/facebook.svg'
import TwitterSvg from '../images/twitter.svg'
import InstagramSvg from '../images/instagram.svg'
import Title from './title'

const Footer = () => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)

  const { wordPress } = useStaticQuery(
    graphql`
      query {
        wordPress {
          menuItems(where: { location: FOOTER_MENU }) {
            edges {
              node {
                connectedObject {
                  ... on WordPress_Page {
                    slug
                  }
                }
                label
              }
            }
          }
          globalOptions {
            globalFields {
              footer {
                address
                facebookLink
                email
                fieldGroupName
                instagramLink
                phone
                tagline
                twitterLink
              }
            }
          }
        }
      }
    `
  )

  return (
    <PageFooter>
      <Logo>
        <LogoSvg />
      </Logo>
      <FooterMain>
        <Left>
          <Title type="p" text={wordPress.globalOptions.globalFields.footer.tagline} />
        </Left>
        <Right>
          <Spacer />
          <Nav>
            <ul>
              {wordPress.menuItems.edges.map((item, i) => {
                const slug =
                  item.node.connectedObject.slug === 'home' ? '' : item.node.connectedObject.slug

                return (
                  <li key={i}>
                    <Link
                      activeClassName="active"
                      to={`/${slug}`}
                      onClick={event => {
                        event.preventDefault()
                        setTransitionActive(true)
                        setTimeout(() => {
                          navigate(`/${slug}`)
                          setTransitionActive(false)
                        }, 500)
                      }}
                      dangerouslySetInnerHTML={{ __html: item.node.label }}
                    />
                  </li>
                )
              })}
            </ul>
          </Nav>

          {/* <Social>
            <a
              href={wordPress.globalOptions.globalFields.footer.facebookLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookSvg />
            </a>
            <a
              href={wordPress.globalOptions.globalFields.footer.twitterLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterSvg />
            </a>
            <a
              href={wordPress.globalOptions.globalFields.footer.instagramLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramSvg />
            </a>
          </Social> */}
        </Right>
      </FooterMain>
      <FooterBottom>
        <ContactDetails>
          <p>{wordPress.globalOptions.globalFields.footer.address}</p>
          <p>
            <span>T : {wordPress.globalOptions.globalFields.footer.phone}</span>{' '}
            <span>
              E :{' '}
              <a href={`mailto:${wordPress.globalOptions.globalFields.footer.email}`}>
                {wordPress.globalOptions.globalFields.footer.email}
              </a>
            </span>
          </p>
        </ContactDetails>
        {/* <Social mobile>
          <a
            href={wordPress.globalOptions.globalFields.footer.facebookLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookSvg />
          </a>
          <a
            href={wordPress.globalOptions.globalFields.footer.twitterLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterSvg />
          </a>
          <a
            href={wordPress.globalOptions.globalFields.footer.instagramLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramSvg />
          </a>
        </Social> */}
        <KotaLogo>
          <a href="https://kota.co.uk" target="_blank" rel="noopener noreferrer">
            <KotaLogoSvg />
          </a>
        </KotaLogo>
      </FooterBottom>
    </PageFooter>
  )
}

export default Footer

const PageFooter = styled.footer`
  position: relative;
  padding: 10rem 16.5%;
  background: #202020;
  color: ${props => props.theme.colours.white};
  font-size: 24px;
  line-height: 2;

  @media (max-width: 1260px) {
    padding: 10rem 7.5%;
  }

  @media (max-width: 650px) {
    padding: 10rem 7.5% 2rem;
  }
`

const Logo = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1260px) {
    justify-content: flex-start;
  }

  svg {
    width: 25rem;

    @media (max-width: 650px) {
      width: 28rem;
    }
  }
`

const FooterMain = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 7rem 0;

  @media (max-width: 650px) {
    padding: 7rem 0 0;
  }
`

const Left = styled.div`
  width: 50%;
  max-width: 350rem;

  @media (max-width: 650px) {
    width: 100%;
    max-width: 100%;
  }

  p {
    font-family: ${props => props.theme.fonts.butler};
    color: #fff;
    font-size: 8rem;
    line-height: 1.1;

    @media (max-width: 650px) {
      font-size: 5rem;
    }
  }
`

const Right = styled.div`
  width: 50%;
  text-align: right;

  @media (max-width: 650px) {
    width: 100%;
    text-align: left;
  }
`

const Nav = styled.nav`
  ul {
    list-style-type: none;
    margin: 0;
    line-height: 1.4;
    margin-bottom: 8rem;

    @media (max-width: 650px) {
      column-count: 2;
    }
  }

  a {
    font-family: ${props => props.theme.fonts.maison};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    transition: color 0.8s ease;

    &:hover {
      color: ${props => props.theme.colours.orange};
    }
  }
`

const Social = styled.div`
  display: ${props => (props.mobile ? 'none' : 'flex')};
  justify-content: flex-end;
  list-style-type: none;

  @media (max-width: 650px) {
    display: ${props => (props.mobile ? 'flex' : 'none')};
  }

  svg {
    width: 3rem;
    height: 3rem;
    margin-left: 1.5rem;
    transition: opacity 0.3s ease;

    @media (max-width: 650px) {
      width: 4rem;
      height: 4rem;
      margin-left: 0;
      margin-right: 2rem;
    }

    &:hover {
      opacity: 0.4;
    }
  }
`

const FooterBottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`

const ContactDetails = styled.div`
  @media (max-width: 650px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  p {
    font-family: ${props => props.theme.fonts.maison};
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 0.3rem;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    opacity: 0.54;

    @media (max-width: 650px) {
      font-size: 1.7rem;
      line-height: 2.5;
    }

    &:first-child {
      @media (max-width: 650px) {
        line-height: 2;
        margin-bottom: 4rem;
      }
    }

    &:last-child {
      @media (max-width: 650px) {
        opacity: 1;
      }
    }

    a {
      color: #fff;
      text-decoration: none;
      transition: color 0.8s ease;

      &:hover {
        color: ${props => props.theme.colours.orange};
      }
    }
  }

  span {
    &:first-child {
      margin-right: 15px;
    }

    @media (max-width: 650px) {
      display: block;
    }
  }
`

const KotaLogo = styled.div`
  opacity: 0.43;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 4.6rem;
    height: 4.6rem;
  }
`

const Spacer = styled.div`
  display: none;
  width: 55px;
  height: 1px;
  background: ${props => props.theme.colours.lightGrey};
  margin: 40px 0;

  @media (max-width: 650px) {
    display: block;
  }
`
