import React from 'react'
import styled from 'styled-components'

const Header = () => <Outer></Outer>

export default Header

const Outer = styled.div`
  /* padding: 100px 0; */
`
