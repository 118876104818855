import React, { useEffect, useState, useRef } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import Scrollbar from 'react-smooth-scrollbar'
import { ScrollbarProvider } from './context/scrollbar-context'

import '../fonts/fonts.css'

// import Bugheard from './bugheard'
import Header from './header'
import Footer from './footer'

const Layout = ({ children }) => {
  const scrollRef = useRef()
  const [scrollbarInstance, setScrollbarInstance] = useState(undefined)
  const [isTouch, setIsTouch] = useState(undefined)

  useEffect(() => {
    setScrollbarInstance(scrollRef.current.scrollbar)
  }, [scrollbarInstance])

  // Check if we're using a touch device and restrict to
  // likely a phone or tablet sizes. Only enable Smooth Scroll
  // on non-touch devices
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const checkTouchDevice = () => 'ontouchstart' in window

      if (checkTouchDevice() && window.innerWidth < 1050) {
        if (scrollbarInstance) {
          setIsTouch(true)
          scrollbarInstance.destroy()
        }
      }
    }
  }, [scrollbarInstance])

  return (
    <>
      {isTouch && (
        <Helmet>
          <body className="has-touch" />
          <html className="has-touch" lang="en-US" />
        </Helmet>
      )}
      <Scrollbar ref={scrollRef} alwaysShowTracks={!isMobile} syncCallbacks renderByPixels>
        <ScrollbarProvider scrollbarInstance={scrollbarInstance}>
          <Header />
          <main className="main">{children}</main>
          <Footer />
          {/* <Bugheard /> */}
        </ScrollbarProvider>
      </Scrollbar>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
