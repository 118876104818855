import React, { useState, useRef } from 'react'
import { useTrail, animated, interpolate } from 'react-spring'
import styled from 'styled-components'
import InView from './in-view'

const Title = ({ type, text }) => {
  const ref = useRef()
  // Split the text string into an array of words
  const splitText = text.split(' ')

  const [animationToggle, setAnimationToggle] = useState(undefined)

  const wordAnimation = useTrail(splitText.length, {
    y: animationToggle ? 0 : 30,
    rotate: animationToggle ? 0 : 5,
    opacity: animationToggle ? 1 : 0,
    from: { rotate: 5, y: 30, opacity: 0 },
    config: {
      mass: 5,
      tension: 1200,
      friction: 200
    }
  })

  const Tag = type

  return (
    <>
      <InView element={ref.current} toggle={animationToggle} setToggle={setAnimationToggle}>
        <Tag ref={ref}>
          {wordAnimation.map(({ rotate, y, ...rest }, index) => (
            <Word
              key={[index]}
              style={{
                ...rest,
                transform: interpolate(
                  [y, rotate],
                  (y, rotate) => `translateY(${y}px) rotate(${rotate}deg)`
                )
              }}
            >
              {splitText[index]}&nbsp;
            </Word>
          ))}
        </Tag>
      </InView>
    </>
  )
}

export default Title

Title.defaultProps = {
  type: 'h2',
  text: ''
}

const Word = styled(animated.span)`
  display: inline-block;
  transform-origin: left bottom;
  opacity: 0;
  will-change: transform;
`
