import React from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

export const fragment = graphql`
  fragment SeoFragment on WordPress_Page {
    seo {
      title
      metaDesc
      opengraphTitle
      opengraphDescription
      opengraphImage {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      twitterTitle
      twitterDescription
      twitterImage {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fixed(width: 1200, height: 675) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

export const fragment2 = graphql`
  fragment SeoFragmentSinglePortfolio on WordPress_PortfolioItem {
    seo {
      title
      metaDesc
      opengraphTitle
      opengraphDescription
      opengraphImage {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      twitterTitle
      twitterDescription
      twitterImage {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fixed(width: 1200, height: 675) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

export const fragment3 = graphql`
  fragment SeoFragmentSinglePress on WordPress_PressItem {
    seo {
      title
      metaDesc
      opengraphTitle
      opengraphDescription
      opengraphImage {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      twitterTitle
      twitterDescription
      twitterImage {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fixed(width: 1200, height: 675) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

export const fragment4 = graphql`
  fragment SeoFragmentSingleProject on WordPress_Project {
    seo {
      title
      metaDesc
      opengraphTitle
      opengraphDescription
      opengraphImage {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      twitterTitle
      twitterDescription
      twitterImage {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fixed(width: 1200, height: 675) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

function SEO({
  pageTitle,
  title,
  metaDesc,
  opengraphTitle,
  opengraphDescription,
  opengraphImage,
  twitterTitle,
  twitterDescription,
  twitterImage
}) {
  const { wordPress } = useStaticQuery(
    graphql`
      query {
        wordPress {
          globalOptions {
            globalFields {
              seo {
                defaultSocialImage {
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fixed(width: 1200, height: 630) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const metaTitle = title || `${pageTitle} | Martin Building`
  const metaDescription = metaDesc || ''
  const facebookTitle = opengraphTitle || title || `${pageTitle} | Martin Building`
  const facebookDescription = opengraphDescription || metaDesc || ''
  const facebookType = 'website'
  const facebookImage = opengraphImage
    ? opengraphImage.imageFile.childImageSharp.fixed.src
    : wordPress.globalOptions.globalFields.seo.defaultSocialImage.imageFile.childImageSharp.fixed
        .src
  const twitterShareTitle = twitterTitle || title || `${pageTitle} | Martin Building`
  const twitterShareDescription = twitterDescription || metaDesc || ''
  const twitterShareImage = twitterImage
    ? twitterImage.imageFile.childImageSharp.fixed.src
    : wordPress.globalOptions.globalFields.seo.defaultSocialImage.imageFile.childImageSharp.fixed
        .src

  return (
    <Helmet>
      <html lang="en" />
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content={facebookType} />
      <meta property="og:title" content={facebookTitle} />
      <meta property="og:description" content={facebookDescription} />
      <meta property="og:site_name" content="Martin Building" />
      <meta property="og:image" content={facebookImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={twitterShareDescription} />
      <meta name="twitter:title" content={twitterShareTitle} />
      <meta name="twitter:image" content={twitterShareImage} />
      <meta name="format-detection" content="telephone=no" />
    </Helmet>
  )
}

export default SEO
