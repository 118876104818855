import React, { useState, useRef } from 'react'
import { useSpring, animated, config } from 'react-spring'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import BezierEasing from 'bezier-easing'
import InView from './in-view'

const MaskedImage = ({ fluid, alt, offset, isLink = false }) => {
  const [animationToggle, setAnimationToggle] = useState(false)
  const ref = useRef()

  const maskAnimation = useSpring({
    transform: animationToggle ? `translate3d(0,0px,0)` : `translate3d(0,200px,0)`,
    config: config.slow
  })

  const maskAnimationWrapper = useSpring({
    from: {
      transform: `translate3d(0, 101%, 0)`
    },
    to: {
      transform: animationToggle ? `translate3d(0, 0%, 0)` : `translate3d(0, 101%, 0)`
    },
    config: {
      duration: 750,
      easing: animationToggle ? BezierEasing(0.1, 0.305, 0.24, 1) : BezierEasing(0.76, 0, 0.9, 0.69)
    },
    delay: animationToggle ? 0 : 500
  })

  const maskAnimationInner = useSpring({
    from: {
      transform: `translate3d(0, -101%, 0)`
    },
    to: {
      transform: animationToggle ? `translate3d(0, 0%, 0)` : `translate3d(0, -101%, 0)`
    },
    config: {
      duration: 750,
      easing: animationToggle ? BezierEasing(0.1, 0.305, 0.24, 1) : BezierEasing(0.76, 0, 0.9, 0.69)
    },
    delay: animationToggle ? 0 : 500
  })

  return (
    <InView element={ref.current} toggle={animationToggle} setToggle={setAnimationToggle}>
      <ImageContainer style={maskAnimation} className={isLink ? 'link' : ''} ref={ref}>
        <ImageWrapper style={maskAnimationWrapper}>
          <ImageInner style={maskAnimationInner}>
            <Image fluid={fluid} alt={alt} backgroundColor="#242424" />
          </ImageInner>
        </ImageWrapper>
      </ImageContainer>
    </InView>
  )
}

export default MaskedImage

const Image = styled(Img)`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 1s ease;
  will-change: transform;
  z-index: 1;

  &.absolute {
    height: 100%;
  }

  img {
    object-position: 50% top !important;
  }
`

const ImageContainer = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  will-change: transform;
  overflow: hidden;

  &.link {
    &:hover {
      ${Image} {
        transform: scale(1.05);
      }
    }
  }
`

const ImageWrapper = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const ImageInner = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

Image.defaultProps = {
  fluid: '',
  alt: ''
}
